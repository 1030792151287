import React from "react";
import assetify from "@quintype/framework/assetify";
import logo from "../../../../assets/images/vikatan-logo.png";
import styles from "./style.m.css";

const VikatanLoginHeader = () => {
  return (
    <div className={styles["audio-header"]}>
      <div className="container">
        <div className={styles["audio-header-grid"]}>
          <a className={styles["home-link"]} aria-label="Vikatan" href="https://www.vikatan.com/">
            <img alt="Vikatan" src={assetify(logo)} className={styles["brand-logo"]} width="240px" height="50px" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default VikatanLoginHeader;
