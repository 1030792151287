import React from "react";
import PropTypes from "prop-types";
import { omit } from "lodash"; // Replace with your actual utility functions
import FocusedImage from "../../utils/focused-image";
import get from "lodash/get";

const AssettypeImage = props => {
  const {
    slug,
    metadata,
    aspectRatio,
    imageCDN = "media.vikatan.com",
    imgParams,
    reactTag: Tag = "img",
    className,
    widths = [],
    alt,
    ...otherProps
  } = props;

  const image = new FocusedImage(slug, metadata);

  const focusedImagePath = ({ imgParams, slug, metadata, aspectRatio, width }) => {
    const imgAspectRatio = aspectRatio && aspectRatio.map(el => Number(el));
    const newOpts = width ? Object.assign({ w: width }, imgParams) : imgParams;
    const path = metadata ? new FocusedImage(slug, metadata).path(imgAspectRatio, newOpts) : `${slug}?q=60`;
    return `https://${imageCDN}/${path}`;
  };

  const srcSet = widths
    .map(w => {
      const width = w < 50 ? 460 : w;
      return `${focusedImagePath({ imgParams, slug, metadata, aspectRatio, width })} ${width}w`;
    })
    .join(", ");
  const imageProps = {
    src: `https://${imageCDN}/${image.path(
      aspectRatio,
      Object.assign(
        {
          w: get(props, ["defaultWidth"], 700)
        },
        imgParams
      )
    )}`
  };

  return (
    <div>
      <Tag
        {...imageProps}
        {...omit(otherProps, [
          "slug",
          "metadata",
          "aspectRatio",
          "imageCDN",
          "imgParams",
          "reactTag",
          "className",
          "alt"
        ])}
        className={className ? `qt-image ${className}` : "qt-image"}
        srcSet={srcSet}
      />
      <noscript>
        <img src={`https://${imageCDN}/${image.path(aspectRatio, { ...imgParams, w: 1200 })}`} alt={alt || ""} />
      </noscript>
    </div>
  );
};

AssettypeImage.propTypes = {
  slug: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  aspectRatio: PropTypes.string.isRequired,
  imageCDN: PropTypes.string.isRequired,
  imgParams: PropTypes.object,
  reactTag: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string
};

AssettypeImage.defaultProps = {
  imgParams: {},
  className: "",
  alt: ""
};

export default AssettypeImage;
