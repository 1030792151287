export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  BUNDLE_PAGE: "bundle-page",
  MAGAZINE_PAGE: "magazine-page",
  MAGAZINE_PAGE_BUNDLE: "magazine-bundle-page",
  TAG_PAGE: "tag-page",
  AUTHOR_PAGE: "author-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  STORY_STATIC: "story-static",
  TOPICS_PAGE: "topics-page",
  SITE_MAP: "sitemap",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  RASIPALAN_PAGE: "rasipalan-page",
  RASIPALAN_DETAIL_PAGE: "rasipalan-detail-page",
  GURUPEYARCHI_PAGE: "gurupeyarchi-page",
  GURUPEYARCHI_STORY_PAGE: "gurupeyarchi-story-page",
  LAYOUT_API: "layout-api",
  MAGAZINE_LISTING_PAGE: "magazine-listing-page",
  MAGAZINE_ARCHIVE_SEARCH_PAGE: "magazine-archive-page",
  SPECIAL_PAGE: "special-page",
  STORY_FEED: "story-feed",
  IPL_PAGE: "ipl-page",
  SERIES_PAGE: "vikatan-series-page",
  VISUAL_STORY: "visual-story",
  WEBSTORIES_PAGE: "webstories-page",
  VIKATAN_SERIES_PAGE: "VikatanSeriesPage",
  VI_BUNDLE_PAGE: "vibundle-page",
  VIDEO_PAGE: "video-page",
  TAX_PAGE: "tax-page",
  STORY_PAGE_GLANCE: "glance-story",
  ELECTION_PAGE: "election-page",
  FLAMES_PAGE: "flames-page",
  AKS_PAGE: "aks-page",
  CONSTITUENCY_PAGE: "constituency-page",
  LOKSABHA_ELECTION_PAGE: "loksabha-election-page",
  LOKSABHA_HOME_PAGE: "loksabha-home-page",
  LOKSABHA_CANDIDATES_PAGE: "loksabha-candidates-page",
  SHORTS_PAGE: "shorts-page",
  CANDIDATE_PAGE: "candidate-page",
  ELECTION_RESULT_PAGE: "election-result-page",
  CANDIDATE_RESULT_PAGE: "candidate-result-page",
  FORYOU_PAGE: "foryou-page",
  RECOMMEND_PAGE: "recommend-page",
  SITE_MAP_: "site-map",
  SITE_MAP_YEAR: "site-map-year",
  SITE_MAP_MONTH: "site-map-month",
  SITE_MAP_DATE: "site-map-date",
  SHORT_NEWS: "short-news",
  GURUPEYARCHI_STATIC: "gurupeyachi-static",
  RAAGUKEDHUPEYARCHI_STATIC: "raaghu-kedhu-static",
  VIKATAN_DEALS: "vikatan-deals",
  VIKATAN_DEALS_PRODUCT: "vikatan-deals-product",
  VIKATAN_DEALS_CATEGORY_PAGE: "vikatan-deals-category-page",
  VIKATAN_DEALS_PRIVACYPOLICY: "vikatan-deals-privacy-policy",
  VIKATAN_DEALS_ABOUTUS: "vikatan-deals-aboutus",
  VIKATAN_DEALS_TERMS: "vikatan-deals-terms",
  VIKATAN_DEALS_CONTACTUS: "vikatan-deals-contactus",
  VIKATAN_DEALS_CSV_DOWNLOAD: "vikatan-deals-csv-download",
  APY_CALCULATOR: "apy-calculator",
  PREMIUM_SERIES_PAGE: "premium-series-page",
  SHORT_STORIES_PAGE: "short-stories-page",
  CITY_COLLECTION_PAGE: "city-collection-page",
  GOOGLESEARCH_PAGE: "google-search-page",
  VIKATAN_LOGIN_PAGE: "vikatan-login-page",
  NEWS_PAGE: "news-page",
  NOT_FOUND: "not-found-page",
  STORE_PAGE: "store-page",
  PDF_PAGE: "pdf-preview",
  WORLDCUP_PAGE: "wc-page",
  ST_IPL_PAGE: "st-ipl-page",
  T20_WORLDCUP_PAGE: "t20-worldcup-page",
  SCORE_PAGE: "score-page",
  AUDIO_PAGE: "audio-page",
  AUTHOR_DEALS_PAGE: "deals-author-page",
  OLYMPICS_PAGE: "olympics-page",
  AUDIOMAGAZINE_PAGE: "audiomagazine-page",
  AUDIOMAGAZINECOLLECTION_PAGE: "audiomagazinecollection-page",
  AUDIOMAGAZINECOLLECTIONLIST_PAGE: "audiomagazinecollectionlist-page",
  NT_PAGE: "nt-page"
});
export const TAG_PAGE_URL_PREFIX = "/topics/";
export const AUTHOR_PAGE_URL_PREFIX = "/author/";
export const RASIPALAN_URL_PREFIX = "/horoscope";

export const STORY_TEMPLATES = Object.freeze({
  TEXT: "text",
  LISTICLE: "listicle",
  PHOTO: "photo",
  VIDEO: "video",
  LONG_FORM: "long-form",
  REVIEW: "review",
  PHOTO_ALBUM: "photo-album",
  LIVE_BLOG: "live-blog",
  EXPLAINER: "explainer",
  APP_STORY: "app-stories",
  CARTOON: "cartoon"
});

export const FIELDS_FOR_LISTING_PAGE =
  "id,headline,slug,hero-image-s3-key,hero-image-metadata,alternative,author-name,author-id,authors,story-template,url,access-level-value,access";

export const FIELDS_FOR_STORY_PAGE =
  "id,headline,slug,cards,metadata,url,hero-image-s3-key,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,author-name,author-id,authors,sections,story-template,tags,access-level-value,access,subheadline";

export const FIELDS_FOR_AUDIO_LISTING_PAGE =
  "id,headline,slug,hero-image-s3-key,hero-image-metadata,alternative,author-name,author-id,authors,story-template,access-level-value,access,metadata";
