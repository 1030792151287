import React from "react";
import styles from "./style.m.css";
import get from "lodash/get";
import { connect } from "react-redux";

const VikatanLoginFooter = () => {
  return (
    <footer className={styles["footer"]}>
      By Continuing, you agree to our{" "}
      <a
        className={styles["footer-links"]}
        href="https://www.vikatan.com/terms-and-conditions-web"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms of service
      </a>{" "}
      &{" "}
      <a
        className={styles["footer-links"]}
        href="https://www.vikatan.com/privacy-policy-web"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy policy
      </a>{" "}
    </footer>
  );
};

function mapStateToProps(state) {
  const data = get(state, ["qt", "data", "footer"]);
  const pageLoading = get(state, ["pageLoading"], false);
  const vikatanHosts = get(state, ["qt", "config", "vikatanHosts"]);
  return data ? { ...data, pageLoading, vikatanHosts } : {};
}

export default connect(mapStateToProps)(VikatanLoginFooter);
