import React from "react";
import PropTypes from "prop-types";
import VikatanStoryFallback from "../../shared/icons/fallbackImage";
import { PremiumBadge } from "../../atoms/premium-badge";
import { omit, get } from "lodash";
import { MyVikatanBadge } from "../my-vikatan-badge";
import { MyVikatanBadgeArticle } from "../my-vikatan-badge-article";
import { MyVikatanBadgeSmall } from "../my-vikatan-badge-small";
import AssettypeImage from "./assetypeImage";

const ResponsiveImageWithFallback = ({
  className,
  story,
  children,
  sources,
  zoom = true,
  style,
  isPremium,
  cardType,
  imagedefaultratio = true,
  ...props
}) => {
  const cluster = get(story, ["metadata", "story-attributes", "cluster", "0"], "");
  let responsiveSources = [];
  let image = null;
  let originalSources = [...sources];
  if (sources && sources.length) {
    responsiveSources = originalSources
      .slice(0, -1)
      .map((source, index) => <AssettypeImage key={index} {...omit(props, ["alt"])} {...source} />);
    image = originalSources[originalSources.length - 1];
  }

  return (
    <React.Fragment>
      <figure
        className={`${className}  ${
          imagedefaultratio ? "default-ratio" : "default-ratio-none"
        } qt-figure myvikatan-wrapper`}
        style={style}
      >
        {props.slug ? (
          <picture className={`qt-image ${zoom ? "zoom-desktop" : ""}`}>
            {responsiveSources.length ? responsiveSources : null}
            <AssettypeImage {...props} {...image} />
          </picture>
        ) : (
          <picture className={`qt-image ${zoom ? "zoom-desktop" : ""}`}>
            <VikatanStoryFallback />
          </picture>
        )}

        {children}
        <PremiumBadge isPremium={isPremium} />
        {cardType === "hero-banner" ? (
          <MyVikatanBadgeArticle isMyVikatan={cluster === "MyVikatan"} />
        ) : cardType === "small-card" ? (
          <MyVikatanBadgeSmall isMyVikatan={cluster === "MyVikatan"} />
        ) : (
          <MyVikatanBadge isMyVikatan={cluster === "MyVikatan"} />
        )}
      </figure>
    </React.Fragment>
  );
};

ResponsiveImageWithFallback.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  zoom: PropTypes.bool,
  sources: PropTypes.arrayOf(PropTypes.object)
};

export default ResponsiveImageWithFallback;
