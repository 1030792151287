import cookie from "cookie";
import Cookie from "universal-cookie";
import { get } from "lodash";
import dateFormat from "date-fns/format";
import wretch from "wretch";
import uuid from "uuid/v4";
import { fetchAuthorDetails } from "./api";
import { isPremium } from "./data/story";
import { getParentSection } from "./data/section";
import { STORY_TEMPLATES, PAGE_TYPE } from "./constants";
const universalCookie = new Cookie();

function getDeviceTrackerId() {
  const thinmint = universalCookie.get("thinmint") || uuid();
  const vikatanThinmint = universalCookie.get("vikatanthinmint");

  if (!vikatanThinmint) {
    const expireDate = 3600 * 24 * 3600 * 1000;
    universalCookie.set("vikatanthinmint", thinmint, {
      expires: new Date(Date.now() + expireDate),
      path: "/",
      domain: ".vikatan.com"
    });
  }

  let cookies = cookie.parse(document.cookie);
  return cookies["vikatanthinmint"] || thinmint;
}

function getContentSource(story) {
  return get(story, ["metadata", "story-attributes", "source", 0]);
}

function getNewsType(story) {
  // Short News: SN,
  // Else: N
  return get(story, ["metadata", "story-attributes", "shortnews", 0]) === "true" ? "SN" : "N";
}

function getContentPostedShift(story) {
  // Night Shift: NS,
  // General Shift: GS
  // Add from the story attributes for backward compatibility
  const newsDateFromMeta = get(story, ["metadata", "story-attributes", "newsdate", 0]);
  const createdAt = newsDateFromMeta ? new Date(newsDateFromMeta) : new Date(story["created-at"]);
  return createdAt.getHours() >= 21 || createdAt.getHours() <= 7 ? "NS" : "GS";
}

function getDimension4(pageType, { section, story, collection, entity, author }) {
  switch (pageType) {
    case PAGE_TYPE.HOME_PAGE:
      return "home";
    case PAGE_TYPE.SECTION_PAGE:
      return get(section, ["name"], "");
    case PAGE_TYPE.STORY_PAGE:
      return `${get(story, ["sections", 0, "name"], "")} | ${get(
        story,
        ["metadata", "story-attributes", "theme", 0],
        ""
      )} | ${getContentSource(story)} | ${getNewsType(story)} | ${getContentPostedShift(story)}`;
    case PAGE_TYPE.RASIPALAN_PAGE:
      return get(story, ["slug"], "Unknown");
    case PAGE_TYPE.GURUPEYARCHI_STORY_PAGE:
      return get(story, ["slug"], "Unknown");
    case PAGE_TYPE.GURUPEYARCHI_PAGE:
    case PAGE_TYPE.BUNDLE_PAGE:
      return collection.name;
    case PAGE_TYPE.TOPIC_PAGE:
      return `${get(entity, ["name"], "Unknown")} | ${get(entity, ["tamil-name"], "Unknown")}`;
    case PAGE_TYPE.AUTHOR_PAGE:
      return `${get(author, ["metadata", "author_name_en"], "Unknown")} | ${get(author, ["name"], "Unknown")}`;
  }
}

function getDimension5({ story, section, collection, entity, author }) {
  if (!story && !section && !collection && !entity && !author) {
    return "Unknown";
  }
  if (entity) {
    return `${get(entity, ["entity-type-id"], "Unknown")}-${get(entity, ["id"], "Unknown")}`;
  }

  if (author) {
    return get(author, ["id"], "Unknown");
  }

  if (!story && collection) {
    // magazine landing page
    const magazineEntity = get(collection, ["metadata", "entities", "collectionEntities", "magazine", 0]);
    if (magazineEntity) {
      return `${magazineEntity.id}-${collection.id}`;
    } else {
      return collection["id"];
    }
  }

  section = section || get(story, ["sections", 0]);

  if (section) {
    // article or section pages
    const parentSection = getParentSection(section);
    let sections = [section];
    if (parentSection) {
      sections.unshift(parentSection);
    }

    return sections.map(section => section["name"]).join("-");
  }

  return null;
}

// Separate function to remove query string from URL
function removeQueryString(url) {
  return url ? url.split("?")[0] : null;
}

function extractAdUnitName(adUnitPath) {
  const lastSlashIndex = adUnitPath.lastIndexOf("/");
  return lastSlashIndex !== -1 ? adUnitPath.substring(lastSlashIndex + 1) : null;
}

function intoVal(text) {
  const a = ["f", "s", "w", "t", "y", "u", "n", "q", "a", "i"];
  const b = [6, 3, 8, 7, 4, 2, 0, 5, 9, 1];
  const c = text.split("");
  const count = text.length;

  if (count === 1) {
    return a.indexOf(c[0]);
  } else if (count === 2) {
    return a.indexOf(c[0]) + "" + b.indexOf(parseInt(c[1]));
  } else if (count === 3) {
    return a.indexOf(c[0]) + "" + b.indexOf(parseInt(c[1])) + "" + a.indexOf(c[2]);
  } else if (count === 4) {
    return a.indexOf(c[0]) + "" + b.indexOf(parseInt(c[1])) + "" + b.indexOf(parseInt(c[2])) + "" + a.indexOf(c[3]);
  } else {
    let final = "";
    for (let i = 0; i < count; i++) {
      if (i === 1) {
        final += b.indexOf(parseInt(c[i]));
      } else if (i === 4) {
        final += a.indexOf(c[i]);
      } else if (i === count - 1) {
        final += a.indexOf(c[i]);
      } else {
        final += b.indexOf(parseInt(c[i]));
      }
    }
    return final;
  }
}
export const GA = {
  inited: false,
  eventInited: false,
  loadScript(cb) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;
    const node = document.getElementsByTagName("script")[0];
    global.qtInitialGA = function() {
      global.ga("create", global.qtConfig.integrations.gaKey, "auto");
      cb();
    };
    script.onload = global.qtInitialGA;
    script.src = `https://www.google-analytics.com/analytics.js`;
    node.parentNode.insertBefore(script, node);
    global["GoogleAnalyticsObject"] = "ga";
  },
  registerPageView(pageType, { section, story, collection, entity, author, infiniteTagRequired } = {}) {
    try {
      if (!this.inited) {
        this.loadScript(registerPageViewImpl.bind(this));
        this.inited = true;
        return;
      }
      registerPageViewImpl.call(this);
    } catch (e) {
      console.error(e);
    }

    function triggerPageView(user) {
      global.ga(function(tracker) {
        var clientId = tracker.get("clientId");
        if (clientId === "") {
          clientId = "Unknown";
        }
        global.ga("set", "dimension1", user.userType || "Unknown");
        global.ga("set", "dimension2", user.userId || "Unknown");
        global.ga("set", "dimension3", getDeviceTrackerId() || "Unknown");
        global.ga(
          "set",
          "dimension4",
          getDimension4(pageType, { section, story, collection, entity, author }) || "Unknown"
        );
        global.ga("set", "dimension5", getDimension5({ section, story, collection, entity, author }) || "Unknown");
        global.ga("set", "dimension6", clientId);
        global.ga("set", "dimension8", get(story, ["story-template"], "Unknown")); // dimension7 is used for test purpose
        const isMagazineIssue = !!get(collection, ["metadata", "entities", "collectionEntities", "magazine"], [])
          .length;
        if (isMagazineIssue) {
          global.ga("set", "dimension9", collection["magazineEnName"] || "Unknown");
          const issueDate = new Date(collection["collection-date"] || null);
          const issueDateStringified = dateFormat(issueDate, "d-MMMM-yyyy");
          global.ga("set", "dimension10", issueDateStringified, "Unknown");
        } else {
          global.ga("set", "dimension9", "Unknown");
          global.ga("set", "dimension10", "Unknown");
        }
        if (infiniteTagRequired) {
          global.ga("set", "dimension11", "infinite");
        } else {
          global.ga("set", "dimension11", "Unknown");
        }

        let cookies = cookie.parse(document.cookie);
        if (cookies["story_c"]) {
          global.ga("set", "dimension13", cookies["story_c"]);
        }

        if (pageType === PAGE_TYPE.STORY_PAGE) {
          global.ga("set", "dimension14", get(story, ["id"]));
          global.ga("set", "dimension15", get(story, ["author-name"]));
        }
        global.ga("send", "pageview", {
          page: `${window.location.pathname}${window.location.search}`,
          hitCallback: () => {
            if (user && user.userId && global.navigator && global.navigator.sendBeacon) {
              global.navigator.sendBeacon(`/api/vikatan/v1/ping-user/ga/${user.userId}`);
            }
          }
        });
      });
    }
    function registerPageViewImpl() {
      if (!global.ga) {
        return;
      }
      if (global.userPromise) {
        global.userPromise.catch(() => ({})).then(user => {
          triggerPageView(user || {});
        });
      } else {
        triggerPageView({});
      }
    }
  },
  registerPageEvent(category, action, label) {
    try {
      if (!this.eventInited) {
        this.loadScript(registerPageEventImpl.bind(this));
        this.eventInited = true;
        return;
      }
      registerPageEventImpl.call(this);
    } catch (e) {
      console.error(e);
    }

    function triggerPageEvent() {
      global.ga && global.ga("send", "event", category, action, label);
    }
    function registerPageEventImpl() {
      if (!global.ga) {
        return;
      }
      triggerPageEvent();
    }
  },
  customEvent(category, action, label) {
    global.ga && global.ga("send", "event", category, action, label);
    global.gtag &&
      global.gtag("event", category, {
        event_category: action,
        event_label: label
      });
  }
};

export const RazorPay = {
  inited: false,
  loadScript() {
    if (this.inited) {
      return;
    }
    let e = window.document.createElement("script");
    e.type = "text/javascript";
    e.charset = "utf-8";
    e.setAttribute("crossorigin", "anonymous");
    e.id = "razorpay";
    e.defer = true;
    e.src = "https://checkout.razorpay.com/v1/checkout.js";
    let t = window.document.getElementsByTagName("head")[0];
    t.appendChild(e);
    this.inited = true;
  }
};

export const currentStory = {
  storyCookie(story, testgroup = "") {
    let cookies = new Cookie();
    cookies.set("currentStory", JSON.stringify({ id: story.id, testgroup: testgroup }), {
      path: "/",
      domain: ".vikatan.com"
    });
  },
  setStorydata(story, issueCollection) {
    let storyinfo = isPremium(story) ? "P" : "F";
    const sections = [get(story, ["sections", 0], {})];
    const parentSection = getParentSection(sections[0]);
    if (parentSection) {
      sections.unshift(parentSection);
    }

    let paywallobj = {
      ArticleTitle: story.headline,
      ArticleID: story.id,
      Category: get(sections, [0, "name"]) || "",
      SubCategory: get(sections, [1, "name"]) || "",
      ArticleType: storyinfo
    };

    if (storyinfo === "P") {
      if (issueCollection) {
        const issueDate = new Date(issueCollection["collection-date"] || null) || null;
        const issueDateStringified = dateFormat(issueDate, "d-MMMM-yyyy");
        paywallobj.IssueDate = issueDateStringified || "";
        paywallobj.Magazine = get(issueCollection, ["magazineEnName"]);
      }
    }
    paywallobj.Path = get(story, ["url"]);
    this.story = paywallobj;
  },
  getStorydata() {
    return this.story;
  },
  story: false
};

export const DeepBI = {
  inited: false,
  paywallStory(story, issueCollection, paywallType, testgroup = "", evnames = false) {
    try {
      let gaInitializerListenerId = setInterval(function() {
        if (global.ga) {
          clearInterval(gaInitializerListenerId);
          paywallGAEvent();
        }
      });

      paywallWE();
    } catch (e) {
      console.error(e);
    }

    let cookies = new Cookie();

    evnames = cookies.get("vuid") && !cookies.get("ev-guid") ? "ev_login_displayed" : `${evnames}_paywall_displayed`;

    function paywallGAEvent() {
      global.ga("send", "event", evnames, paywallType, "P");
    }

    function paywallWE() {
      if (global.webengage) {
        global.userPromise.catch(() => null).then(user => {
          let cookies = new Cookie();
          let storyinfo = isPremium(story) ? "P" : "F";
          const sections = [get(story, ["sections", 0], {})];
          const parentSection = getParentSection(sections[0]);
          if (parentSection) {
            sections.unshift(parentSection);
          }

          let paywallobj = {
            PaywallType: paywallType,
            ArticleTitle: story.headline,
            ArticleID: story.id,
            Category: get(sections, [0, "name"]) || "",
            SubCategory: get(sections, [1, "name"]) || "",
            ArticleType: storyinfo
          };

          if (storyinfo === "P") {
            const issueDate = new Date(issueCollection["collection-date"] || null) || null;
            const issueDateStringified = dateFormat(issueDate, "d-MMMM-yyyy");
            paywallobj.ArticleType = storyinfo;
            paywallobj.Magazine = issueCollection["magazineEnName"] || "";
            paywallobj.IssueDate = issueDateStringified || "";
          }

          global.webengage.track(evnames, paywallobj);
          cookies.set("paywallStory", JSON.stringify({ id: story.id, testgroup: testgroup }), {
            path: "/",
            domain: ".vikatan.com"
          });
        });
      }
    }
  },
  PaidDatawall(story, issueCollection, paywallType, testgroup = "", evnames = false) {
    try {
      let gaInitializerListenerId = setInterval(function() {
        if (global.ga) {
          clearInterval(gaInitializerListenerId);
        }
      });

      paywallWE();
    } catch (e) {
      console.error(e);
    }

    evnames = `${evnames}_Datawall_Displayed`;

    function paywallWE() {
      if (global.webengage) {
        global.userPromise.catch(() => null).then(user => {
          let storyinfo = isPremium(story) ? "P" : "F";
          const sections = [get(story, ["sections", 0], {})];
          const parentSection = getParentSection(sections[0]);
          if (parentSection) {
            sections.unshift(parentSection);
          }

          let paywallobj = {
            PaywallType: paywallType,
            ArticleTitle: story.headline,
            ArticleID: story.id,
            Category: get(sections, [0, "name"]) || "",
            SubCategory: get(sections, [1, "name"]) || "",
            ArticleType: storyinfo
          };

          if (storyinfo === "P") {
            const issueDate = new Date(issueCollection["collection-date"] || null) || null;
            const issueDateStringified = dateFormat(issueDate, "d-MMMM-yyyy");
            paywallobj.ArticleType = storyinfo;
            paywallobj.Magazine = issueCollection["magazineEnName"] || "";
            paywallobj.IssueDate = issueDateStringified || "";
          }
          global.webengage.track(evnames, paywallobj);
        });
      }
    }
  },
  FreeDatawall(story, paywallType, testgroup = "", evnames = false) {
    try {
      let gaInitializerListenerId = setInterval(function() {
        if (global.ga) {
          clearInterval(gaInitializerListenerId);
        }
      });

      paywallWE();
    } catch (e) {
      console.error(e);
    }

    evnames = `${evnames}_Datawall_Displayed`;

    function paywallWE() {
      if (global.webengage) {
        global.userPromise.catch(() => null).then(user => {
          let storyinfo = isPremium(story) ? "P" : "F";
          const sections = [get(story, ["sections", 0], {})];
          const parentSection = getParentSection(sections[0]);
          if (parentSection) {
            sections.unshift(parentSection);
          }

          let paywallobj = {
            PaywallType: paywallType,
            ArticleTitle: story.headline,
            ArticleID: story.id,
            Category: get(sections, [0, "name"]) || "",
            SubCategory: get(sections, [1, "name"]) || "",
            ArticleType: storyinfo
          };

          paywallobj.ArticleType = storyinfo;
          paywallobj.Magazine = "";
          paywallobj.IssueDate = "";
          global.webengage.track(evnames, paywallobj);
        });
      }
    }
  },
  registerPageView({ story, collection, glance } = {}) {
    if (story && window.location.pathname !== "/story-feed") {
      const sections = [get(story, ["sections", 0], {})];
      const parentSection = getParentSection(sections[0]);
      if (parentSection) {
        sections.unshift(parentSection);
      }
      // Coin Ping
      story &&
        global.userPromise.catch(() => null).then(user => {
          DeepBI.pingVikatanApi(story, user, glance);
        });

      story &&
        global.userPromise.catch(() => null).then(user => {
          let userId = get(user, ["userId"], "");
          if (userId === "826969") {
            DeepBI.pingBeatVikatanApi(story, user);
          }
        });
    }

    if (collection) {
      global.userPromise.catch(() => null).then(user => {
        console.log("user ping hit");
        DeepBI.pingVikatanPageApi(collection, user);
      });
    }
  },

  pingVikatanApi(story, user, glance) {
    var customer = {
      userId: get(user, ["userId"], ""),
      username: get(user, ["username"], ""),
      userType: get(user, ["userType"], ""),
      userEmail: get(user, ["userEmail"], ""),
      userJoinDate: get(user, ["userJoinDate"], "")
    };
    var userSess;

    const now = new Date();
    const minutes = 30;
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const uniq = Math.random()
      .toString(16)
      .slice(2);
    const uniqDate = new Date().getTime();
    const uniqId = `${uniqDate}i${uniq}`;
    if (!universalCookie.get("vikatanSessionID")) {
      universalCookie.set("vikatanSessionID", `${uniqId}`, {
        path: "/",
        domain: ".vikatan.com",
        expires: now
      });
    }

    let sections = get(story, ["sections", 0], null);
    let storyinfo = isPremium(story) ? "P" : "F";
    const section = get(global.qtConfig, ["sections"], []);
    const parentSection = section && section.find(s => sections["parent-id"] === s.id);
    const tags = get(story, ["tags"], null);
    const tagcomma = tags && tags.map(x => x.name).join(",");
    let title = get(story, ["headline"], "");
    let seoTitle = get(story, ["seo", "meta-title"], title);
    var gaClientId = "";

    if (get(global, ["webengage", "state", "getSession"])) {
      userSess = global.webengage.state.getSession();
      Object.assign(customer, {
        ip: get(userSess, ["ip"]),
        city: get(userSess, ["we_city"]),
        country: get(userSess, ["we_country"])
      });
    }

    global.fetch(`https://ping.vikatan.com/?aid=${story.id}&userid=${customer.userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          user: {
            cookie: getDeviceTrackerId(),
            customer: customer
          },
          event: {
            type: "ArticleView",
            timestamp: Date.now(),
            eventattribute: {
              AID: story.id,
              Atitle: story.headline,
              pageTitle: seoTitle,
              Atype: storyinfo,
              tags: tagcomma,
              sequence: 0,
              author: get(story, ["author-name"]),
              url: global.location.href,
              path: story.slug,
              pageType: "story-page",
              section: get(parentSection, ["name"], null),
              subSection: get(sections, ["name"], null),
              date: get(story, ["published-at"]),
              subtitle: get(story, ["sub-headline"]),
              domain: window.location.hostname,
              source: glance === true ? "glance" : "web",
              location: document && document.location,
              referrer: document && document.referrer,
              "story-attributes": get(story, ["metadata", "story-attributes"])
            }
          },
          device: {
            devicetype: getDeviceType(),
            CookieID: getDeviceTrackerId(),
            Platform: getOS(),
            userAgent: navigator.userAgent,
            SessionID: universalCookie.get("vikatanSessionID") || uniqId,
            GACuid: gaClientId || "",
            DeepCookie: "",
            ampthinmint: universalCookie.get("ampthinmint") || ""
          }
        }
      })
    });
  },
  pingBeatVikatanApi(story, user) {
    var customer = {
      userId: get(user, ["userId"], ""),
      username: get(user, ["username"], ""),
      userType: get(user, ["userType"], ""),
      userEmail: get(user, ["userEmail"], ""),
      userJoinDate: get(user, ["userJoinDate"], "")
    };
    var userSess;

    const now = new Date();
    const minutes = 30;
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const uniq = Math.random()
      .toString(16)
      .slice(2);
    const uniqDate = new Date().getTime();
    const uniqId = `${uniqDate}i${uniq}`;
    if (!universalCookie.get("vikatanSessionID")) {
      universalCookie.set("vikatanSessionID", `${uniqId}`, {
        path: "/",
        domain: ".vikatan.com",
        expires: now
      });
    }

    let sections = get(story, ["sections", 0], null);
    let storyinfo = isPremium(story) ? "P" : "F";
    const section = get(global.qtConfig, ["sections"], []);
    const parentSection = section && section.find(s => sections["parent-id"] === s.id);
    const tags = get(story, ["tags"], null);
    const tagcomma = tags && tags.map(x => x.name).join(",");
    let title = get(story, ["headline"], "");
    let seoTitle = get(story, ["seo", "meta-title"], title);
    var gaClientId = "";

    if (get(global, ["webengage", "state", "getSession"])) {
      userSess = global.webengage.state.getSession();
      Object.assign(customer, {
        ip: get(userSess, ["ip"]),
        city: get(userSess, ["we_city"]),
        country: get(userSess, ["we_country"])
      });
    }

    global.fetch(`https://ping.vikatan.com/v2?aid=${story.id}&userid=${customer.userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          user: {
            cookie: getDeviceTrackerId(),
            customer: customer
          },
          event: {
            type: "ArticleView",
            timestamp: Date.now(),
            eventattribute: {
              AID: story.id,
              Atitle: story.headline,
              pageTitle: seoTitle,
              Atype: storyinfo,
              tags: tagcomma,
              sequence: 0,
              author: get(story, ["author-name"]),
              url: global.location.href,
              path: story.slug,
              pageType: "story-page",
              section: get(parentSection, ["name"], null),
              subSection: get(sections, ["name"], null),
              date: get(story, ["published-at"]),
              subtitle: get(story, ["sub-headline"]),
              domain: window.location.hostname,
              source: "web",
              location: document && document.location,
              referrer: document && document.referrer,
              "story-attributes": get(story, ["metadata", "story-attributes"])
            }
          },
          device: {
            devicetype: getDeviceType(),
            CookieID: getDeviceTrackerId(),
            Platform: getOS(),
            userAgent: navigator.userAgent,
            SessionID: universalCookie.get("vikatanSessionID") || uniqId,
            GACuid: gaClientId || "",
            DeepCookie: "",
            ampthinmint: universalCookie.get("ampthinmint") || ""
          }
        }
      })
    });
  },
  pingVikatanPageApi(collection, user) {
    const pageType = get(collection, ["pageType"], "");
    const cId = get(collection, ["data", "collection", "id"], "");
    const cName = get(collection, ["data", "collection", "name"], "");
    const pageTitle = get(collection, ["title"], "");

    var customer = {
      userId: get(user, ["userId"], ""),
      username: get(user, ["username"], ""),
      userType: get(user, ["userType"], ""),
      userEmail: get(user, ["userEmail"], ""),
      userJoinDate: get(user, ["userJoinDate"], "")
    };
    var userSess;

    const now = new Date();
    const minutes = 30;
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const uniq = Math.random()
      .toString(16)
      .slice(2);
    const uniqDate = new Date().getTime();
    const uniqId = `${uniqDate}i${uniq}`;
    if (!universalCookie.get("vikatanSessionID")) {
      universalCookie.set("vikatanSessionID", `${uniqId}`, {
        path: "/",
        domain: ".vikatan.com",
        expires: now
      });
    }
    var gaClientId = "";

    if (get(global, ["webengage", "state", "getSession"])) {
      userSess = global.webengage.state.getSession();
      Object.assign(customer, {
        ip: get(userSess, ["ip"]),
        city: get(userSess, ["we_city"]),
        country: get(userSess, ["we_country"])
      });
    }

    global.fetch(`https://ping.vikatan.com/?aid=${cId}&userid=${customer.userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          user: {
            cookie: getDeviceTrackerId(),
            customer: customer
          },
          event: {
            type: "PageView",
            timestamp: Date.now(),
            eventattribute: {
              AID: cId,
              pageTitle: pageTitle,
              Atitle: cName,
              pageType: pageType,
              url: global && global.location.href,
              path: global && global.location.pathname,
              domain: window.location.hostname,
              source: "web",
              location: document && document.location,
              referrer: document && document.referrer
            }
          },
          device: {
            devicetype: getDeviceType(),
            CookieID: getDeviceTrackerId(),
            Platform: getOS(),
            userAgent: navigator.userAgent,
            SessionID: universalCookie.get("vikatanSessionID") || uniqId,
            GACuid: gaClientId || "",
            DeepCookie: "",
            ampthinmint: universalCookie.get("ampthinmint") || ""
          }
        }
      })
    });
  },

  pingPaywallEventTrigger(story, eventName = "PaywallDisplayed") {
    story &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let sections = get(story, ["sections", 0], null);
        const section = get(global.qtConfig, ["sections"], []);
        const parentSection = section && section.find(s => sections["parent-id"] === s.id);
        const tags = get(story, ["tags"], null);
        const tagcomma = tags && tags.map(x => x.name).join(",");
        let title = get(story, ["headline"], "");
        let seoTitle = get(story, ["seo", "meta-title"], title);
        var gaClientId = "";

        global.fetch(`https://ping.vikatan.com/events?aid=${story.id}&userid=${customer.userId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: eventName,
                timestamp: Date.now(),
                eventattribute: {
                  AID: story.id,
                  Atitle: story.headline,
                  pageTitle: seoTitle,
                  Atype: isPremium(story) ? "P" : "F",
                  tags: tagcomma,
                  author: get(story, ["author-name"]),
                  url: global.location.href,
                  path: story.slug,
                  pageType: "story-page",
                  section: get(parentSection, ["name"], null),
                  subSection: get(sections, ["name"], null),
                  date: get(story, ["published-at"]),
                  subtitle: get(story, ["sub-headline"]),
                  domain: window.location.hostname,
                  source: "web",
                  location: document && document.location,
                  referrer: document && document.referrer
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  pingFreeDatawallEventTrigger(story) {
    story &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let sections = get(story, ["sections", 0], null);
        const section = get(global.qtConfig, ["sections"], []);
        const parentSection = section && section.find(s => sections["parent-id"] === s.id);
        const tags = get(story, ["tags"], null);
        const tagcomma = tags && tags.map(x => x.name).join(",");
        let title = get(story, ["headline"], "");
        let seoTitle = get(story, ["seo", "meta-title"], title);
        var gaClientId = "";

        global.fetch(`https://ping.vikatan.com/events?aid=${story.id}&userid=${customer.userId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "FreeDatawallDisplayed",
                timestamp: Date.now(),
                eventattribute: {
                  AID: story.id,
                  Atitle: story.headline,
                  pageTitle: seoTitle,
                  Atype: isPremium(story) ? "P" : "F",
                  tags: tagcomma,
                  author: get(story, ["author-name"]),
                  url: global.location.href,
                  path: story.slug,
                  pageType: "story-page",
                  section: get(parentSection, ["name"], null),
                  subSection: get(sections, ["name"], null),
                  date: get(story, ["published-at"]),
                  subtitle: get(story, ["sub-headline"]),
                  domain: window.location.hostname,
                  source: "web",
                  location: document && document.location,
                  referrer: document && document.referrer
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  pingPaidDatawallEventTrigger(story) {
    story &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let sections = get(story, ["sections", 0], null);
        const section = get(global.qtConfig, ["sections"], []);
        const parentSection = section && section.find(s => sections["parent-id"] === s.id);
        const tags = get(story, ["tags"], null);
        const tagcomma = tags && tags.map(x => x.name).join(",");
        let title = get(story, ["headline"], "");
        let seoTitle = get(story, ["seo", "meta-title"], title);
        var gaClientId = "";

        global.fetch(`https://ping.vikatan.com/events?aid=${story.id}&userid=${customer.userId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "PaidDatawallDisplayed",
                timestamp: Date.now(),
                eventattribute: {
                  AID: story.id,
                  Atitle: story.headline,
                  pageTitle: seoTitle,
                  Atype: isPremium(story) ? "P" : "F",
                  tags: tagcomma,
                  author: get(story, ["author-name"]),
                  url: global.location.href,
                  path: story.slug,
                  pageType: "story-page",
                  section: get(parentSection, ["name"], null),
                  subSection: get(sections, ["name"], null),
                  date: get(story, ["published-at"]),
                  subtitle: get(story, ["sub-headline"]),
                  domain: window.location.hostname,
                  source: "web",
                  location: document && document.location,
                  referrer: document && document.referrer
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  pingPaywallClicked(story) {
    story &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }
        var gaClientId = "";

        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "paywall-clicked",
                timestamp: Date.now(),
                eventattribute: {
                  AID: story.id,
                  Article: get(story, ["headline"]),
                  domain: window.location.hostname,
                  UtmSource: "Paywall_experiment",
                  UtmMedium: "paywall",
                  UtmCampaign: "Paywall_experiment_may22",
                  testGroup: "",
                  location: document && document.location,
                  referrer: document && document.referrer
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  PingMagazineClicked(story) {
    story &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }
        var gaClientId = "";

        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "magazine-homepage-wrapper",
                timestamp: Date.now(),
                eventattribute: {
                  AID: story.id,
                  Article: get(story, ["name"]),
                  domain: window.location.hostname,
                  location: document && document.location,
                  referrer: document && document.referrer,
                  url: global.location.href
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  PingSearchClicked(data) {
    data &&
      global.userPromise.catch(() => null).then(user => {
        let customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        let userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let gaClientId = "";
        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "searchClick",
                timestamp: Date.now(),
                eventattribute: {
                  AID: "",
                  Article: "",
                  searchKeyword: get(data, ["q"]),
                  domain: window.location.hostname,
                  location: document && document.location,
                  referrer: document && document.referrer,
                  url: global.location.href
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  PingAudioplayer(story, eventname, collection, duration) {
    const magazineName = get(collection, ["name"], null);
    const magazineId = get(collection, ["id"], null);

    story &&
      global.userPromise.catch(() => null).then(user => {
        let customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        let userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let sections = get(story, ["sections", 0], null);
        // const section = get(global.qtConfig, ["sections"], []);
        // const parentSection = section && section.find(s => sections["parent-id"] === s.id);
        var gaClientId = "";

        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: eventname,
                timestamp: Date.now(),
                eventattribute: {
                  AudioMagazineID: magazineId,
                  AudioMagazineTitle: magazineName,
                  duration: duration || 0,
                  author: get(story, ["author-name"]),
                  path: story.slug,
                  AudioID: get(story, ["id"]),
                  AudioTitle: get(story, ["headline"]),
                  // Section: get(parentSection, ["name"], null),
                  section: get(sections, ["name"], null),
                  date: get(story, ["published-at"]),
                  domain: window.location.hostname,
                  source: "web",
                  location: document && document.location,
                  referrer: document && document.referrer,
                  "story-attributes": get(story, ["metadata", "story-attributes"])
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),

                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  PingReadmore(story) {
    story &&
      global.userPromise.catch(() => null).then(user => {
        let customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        let userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let sections = get(story, ["sections", 0], null);
        let storyinfo = isPremium(story) ? "P" : "F";
        const section = get(global.qtConfig, ["sections"], []);
        const parentSection = section && section.find(s => sections["parent-id"] === s.id);
        const tags = get(story, ["tags"], null);
        const tagcomma = tags && tags.map(x => x.name).join(",");
        let title = get(story, ["headline"], "");
        let seoTitle = get(story, ["seo", "meta-title"], title);
        var gaClientId = "";

        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "articlereadmore",
                timestamp: Date.now(),
                eventattribute: {
                  AID: story.id,
                  Atitle: story.headline,
                  pageTitle: seoTitle,
                  Atype: storyinfo,
                  tags: tagcomma,
                  author: get(story, ["author-name"]),
                  url: global.location.href,
                  path: story.slug,
                  pageType: "story-page",
                  section: get(parentSection, ["name"], null),
                  subSection: get(sections, ["name"], null),
                  date: get(story, ["published-at"]),
                  subtitle: get(story, ["sub-headline"]),
                  domain: window.location.hostname,
                  source: "web",
                  location: document && document.location,
                  referrer: document && document.referrer,
                  "story-attributes": get(story, ["metadata", "story-attributes"])
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },
  pingPaywallSubscribeNow(data) {
    data &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        var gaClientId = "";

        const TariffName = get(data, ["Product"]);
        const subId = get(data, ["SubID"]);
        const actualPriceINR = get(data, ["Amount"]);
        const OriginalPrice = get(data, ["Original_Price"]);
        const OfferPrice = get(data, ["OfferPrice"]);
        const MobileNumber = get(data, ["mobile_number"]);
        const EmailId = get(data, ["email_id", ""]);
        const OrderId = get(data, ["order_id"]);

        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "PG-PayNow",
                timestamp: Date.now(),
                eventattribute: {
                  ProductCategory: "All Magazine",
                  Product: TariffName,
                  SubID: subId,
                  Amount: actualPriceINR,
                  ActualPrice: OriginalPrice,
                  OfferPrice: OfferPrice,
                  Mobile: MobileNumber,
                  Email: EmailId,
                  OrderID: OrderId,
                  Platform: "Website",
                  domain: window.location.hostname,
                  UtmSource: "Paywall_experiment",
                  UtmMedium: "paywall",
                  UtmCampaign: "Paywall_experiment_may22",
                  location: document && document.location,
                  referrer: document && document.referrer
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },

  PingPDF(collection, mName, issueDate, mID, eventName = "TapReadOnline") {
    collection &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }
        var gaClientId = "";

        const eventData = {
          user: {
            cookie: getDeviceTrackerId(),
            customer: customer
          },
          event: {
            type: eventName,
            timestamp: Date.now(),
            eventattribute: {
              AID: "",
              Article: "",
              path: "",
              section: "",
              subSection: "",
              Magazine: mName,
              IssueDate: issueDate,
              Mid: mID,
              source: "web",
              domain: window.location.hostname,
              location: document && document.location,
              referrer: document && document.referrer,
              url: global.location.href
            }
          },
          device: {
            devicetype: getDeviceType(),
            CookieID: getDeviceTrackerId(),
            Platform: getOS(),
            userAgent: navigator.userAgent,
            SessionID: universalCookie.get("vikatanSessionID"),
            GACuid: gaClientId || "",
            DeepCookie: "",
            ampthinmint: universalCookie.get("ampthinmint") || ""
          }
        };

        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: eventData
          })
        });
      });
  },

  PingOnlinePDFView(collection, mName, issueDate, mID, pageNo, collectionID, eventName = "WebPDFView") {
    collection &&
      global.userPromise.catch(() => null).then(user => {
        var customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        var userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }
        var gaClientId = "";

        const eventData = {
          user: {
            cookie: getDeviceTrackerId(),
            customer: customer
          },
          event: {
            type: eventName,
            timestamp: Date.now(),
            eventattribute: {
              AID: "",
              Article: "",
              path: "",
              section: "",
              subSection: "",
              Magazine: get(collection, ["name"], ""),
              IssueDate: issueDate,
              Mid: mID,
              PageNo: pageNo,
              CollectionID: collectionID,
              EntityName: mName,
              EntityId: mID,
              source: "web",
              domain: window.location.hostname,
              location: document && document.location,
              referrer: document && document.referrer,
              url: global.location.href
            }
          },
          device: {
            devicetype: getDeviceType(),
            CookieID: getDeviceTrackerId(),
            Platform: getOS(),
            userAgent: navigator.userAgent,
            SessionID: universalCookie.get("vikatanSessionID"),
            GACuid: gaClientId || "",
            DeepCookie: "",
            ampthinmint: universalCookie.get("ampthinmint") || ""
          }
        };

        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: eventName,
                timestamp: Date.now(),
                eventattribute: {
                  AID: "",
                  Article: "",
                  path: "",
                  section: "",
                  subSection: "",
                  Magazine: get(collection, ["name"], ""),
                  IssueDate: issueDate,
                  Mid: mID,
                  PageNo: pageNo,
                  CollectionID: collectionID,
                  EntityName: mName,
                  EntityId: mID,
                  source: "web",
                  domain: window.location.hostname,
                  location: document && document.location,
                  referrer: document && document.referrer,
                  url: global.location.href
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });

        global.webengage.track(eventName, eventData);
      });
  },
  PingAdRendered(name, visible) {
    name &&
      global.userPromise.catch(() => null).then(user => {
        let customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        let userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let gaClientId = "";
        global.fetch(`https://ping.vikatan.com/ad_events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "AdRendered",
                timestamp: Date.now(),
                eventattribute: {
                  AID: "",
                  Article: "",
                  source: "web",
                  adUnitName: extractAdUnitName(name),
                  AdisFilled: visible,
                  domain: window.location.hostname,
                  location: document && document.location,
                  referrer: document && document.referrer,
                  url: global.location.href,
                  baseUrl: removeQueryString(global.location.href)
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },
  PingAdImpression(name, visible) {
    name &&
      global.userPromise.catch(() => null).then(user => {
        let customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        let userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let gaClientId = "";
        global.fetch(`https://ping.vikatan.com/ad_events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "AdImpression",
                timestamp: Date.now(),
                eventattribute: {
                  AID: "",
                  Article: "",
                  source: "web",
                  adUnitName: extractAdUnitName(name),
                  impression: visible,
                  domain: window.location.hostname,
                  location: document && document.location,
                  referrer: document && document.referrer,
                  url: global.location.href,
                  baseUrl: removeQueryString(global.location.href)
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },
  PingSubscriptions(ppid, status) {
    ppid &&
      global.userPromise.catch(() => null).then(user => {
        let customer = {
          userId: get(user, ["userId"], ""),
          username: get(user, ["username"], ""),
          userType: get(user, ["userType"], ""),
          userEmail: get(user, ["userEmail"], ""),
          userJoinDate: get(user, ["userJoinDate"], "")
        };

        let userSess;
        if (get(global, ["webengage", "state", "getSession"])) {
          userSess = global.webengage.state.getSession();
          Object.assign(customer, {
            ip: get(userSess, ["ip"]),
            city: get(userSess, ["we_city"]),
            country: get(userSess, ["we_country"])
          });
        }

        let gaClientId = "";
        global.fetch(`https://ping.vikatan.com/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: {
                cookie: getDeviceTrackerId(),
                customer: customer
              },
              event: {
                type: "Gsubscription",
                timestamp: Date.now(),
                eventattribute: {
                  AID: "",
                  Article: "",
                  source: "web",
                  domain: window.location.hostname,
                  location: document && document.location,
                  referrer: document && document.referrer,
                  url: global.location.href,
                  status: status,
                  ppid: ppid
                }
              },
              device: {
                devicetype: getDeviceType(),
                CookieID: getDeviceTrackerId(),
                Platform: getOS(),
                userAgent: navigator.userAgent,
                SessionID: universalCookie.get("vikatanSessionID"),
                GACuid: gaClientId || "",
                DeepCookie: "",
                ampthinmint: universalCookie.get("ampthinmint") || ""
              }
            }
          })
        });
      });
  },
  PingLoginevents(action, status = "", username = "", userData = null) {
    if (!action) {
      console.warn("Action is required.");
      return;
    }
    const email = get(userData, ["email"], "");
    const userType = get(userData, ["userType"], "");
    const VuID = get(userData, ["vuid"], "");

    var customer = {
      userId: intoVal(VuID) || "",
      username: "",
      userType: userType || "",
      userEmail: email || "",
      userJoinDate: ""
    };

    var userSess;
    if (get(global, ["webengage", "state", "getSession"])) {
      userSess = global.webengage.state.getSession();
      Object.assign(customer, {
        ip: get(userSess, ["ip"]),
        city: get(userSess, ["we_city"]),
        country: get(userSess, ["we_country"])
      });
    }

    var gaClientId = "";

    global.fetch(`https://ping.vikatan.com/events`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          user: {
            cookie: getDeviceTrackerId(),
            customer: customer
          },
          event: {
            type: action,
            timestamp: Date.now(),
            eventattribute: {
              source: "web",
              domain: window.location.hostname,
              UtmCampaign: "direct",
              UtmMedium: "website",
              UtmSource: "login_popup",
              location: document && document.location,
              referrer: document && document.referrer,
              status: status,
              username: username
            }
          },
          device: {
            devicetype: getDeviceType(),
            CookieID: getDeviceTrackerId(),
            Platform: getOS(),
            userAgent: navigator.userAgent,
            SessionID: universalCookie.get("vikatanSessionID"),
            GACuid: gaClientId || "",
            DeepCookie: "",
            ampthinmint: universalCookie.get("ampthinmint") || ""
          }
        }
      })
    });
  }
};

export const WebEngage = {
  userRegistered: false,
  inited: false,
  sjFlag: false,
  loadScript() {
    this.inited = true;
  },
  registerUser() {
    if (!global.webengage || this.userRegistered) {
      return;
    }
    global.userPromise
      .then(user => {
        const { userId, username, userEmail, userMobileNumber } = user;
        global.webengage.user.setAttribute("Cookie_Id", getDeviceTrackerId());
        // If User logged In pass below attributes
        global.webengage.user.setAttribute("User_Id", userId);
        global.webengage.user.login(userId);
        global.webengage.user.setAttribute("we_first_name", username);
        global.webengage.user.setAttribute("we_email", userEmail);
        userMobileNumber && global.webengage.user.setAttribute("we_phone", userMobileNumber);
      })
      .catch(() => {
        console.warn("User attribution error occured");
      });

    this.userRegistered = true;
  },
  registerPageView({ story, section, triggerArticleEvent = true } = {}) {
    try {
      if (!this.inited) {
        window.webengageRegisterPageView = registerPageViewImpl.bind(this);
        this.loadScript();
        registerPageViewImpl.call(this);
        return;
      }

      registerPageViewImpl.call(this);
    } catch (e) {
      console.error(e);
    }

    function registerPageViewImpl() {
      const current = new Date();
      const nextWeek = new Date();
      nextWeek.setDate(current.getDate() + 28);
      if (!global.webengage) {
        return;
      }
      this.registerUser();

      global.webengage.track("Page View", {
        PageTitle: global.document.title,
        PagePath: global.location.href
      });

      let cookies = cookie.parse(document.cookie);
      if (cookies["vusr_unique_views"] !== "1") {
        let d = new Date();
        d.setUTCHours(23, 59, 59, 999);
        let cookiesnew = new Cookie();
        cookiesnew.set("vusr_unique_views", "1", { path: "/", expires: d, domain: ".vikatan.com" });
        global.webengage.track("Unique Visit", {
          PageTitle: global.document.title,
          PagePath: global.location.href
        });
      }

      let type = "Article View";
      if (story && triggerArticleEvent) {
        if (story["story-template"] === STORY_TEMPLATES.VIDEO) {
          type = "Video";
        }
        if (story["story-template"] === STORY_TEMPLATES.PHOTO_ALBUM) {
          type = "Album";
        }
        if (story["story-template"] === "podcast") {
          type = "podcast";
        }
        const sections = [get(story, ["sections", 0], {})];
        const parentSection = getParentSection(sections[0]);
        if (parentSection) {
          sections.unshift(parentSection);
        }

        fetchAuthorDetails(story).then(author => {
          global.webengage.track(type, {
            ArticleTitle: story.headline,
            ArticleID: story.id,
            Category: get(sections, [0, "name"]),
            SubCategory: get(sections, [1, "name"]),
            Author: get(author, ["metadata", "nickname_en"]) || get(author, ["metadata", "nickname_ta"]) || author.name,
            ArticleType: isPremium(story) ? "Paid" : "Free",
            tags: story.tags.map(tag => tag.name).join(","),
            Variant: cookies["story_c"] ? cookies["story_c"] : "",
            cluster: get(story, ["metadata", "story-attributes", "cluster", 0]),
            magazine: get(story, ["metadata", "story-attributes", "magazine", 0]),
            magazineconversion: get(story, ["metadata", "story-attributes", "magazineconversion", 0]),
            newsneeds: get(story, ["metadata", "story-attributes", "newsneeds", 0]),
            shortnews: get(story, ["metadata", "story-attributes", "shortnews", 0]),
            theme: get(story, ["metadata", "story-attributes", "theme", 0]),
            contenttype: get(story, ["metadata", "story-attributes", "articletype", 0]),
            location: get(story, ["metadata", "story-attributes", "location", 0])
          });
        });
      }

      if (section) {
        let sections = [section];
        const parentSection = getParentSection(section);
        if (parentSection) {
          sections.unshift(parentSection);
        }

        window.webengage &&
          window.webengage.track("Category View", {
            Category: get(sections, [0, "name"])
          });
      }
    }
  },
  pwEvent(ev, args) {
    try {
      if (!this.inited) {
        this.loadScript(triggerEv.bind(this));
        return;
      }
      triggerEv.call(this);
    } catch (e) {
      console.error(e);
    }

    function triggerEv() {
      window.webengage && window.webengage.track(ev, args);
    }
  },
  WebengagePaywallSubscribeNow(data) {
    const TariffName = get(data, ["Product"]);
    const subId = get(data, ["SubID"]);
    const actualPriceINR = get(data, ["Amount"]);
    const OriginalPrice = get(data, ["Original_Price"]);
    const OfferPrice = get(data, ["OfferPrice"]);
    const MobileNumber = get(data, ["mobile_number"]);
    const EmailId = get(data, ["email_id"], "");
    const OrderId = get(data, ["order_id"]);

    if (data) {
      window.webengage &&
        window.webengage.track("PG PayNow", {
          ProductCategory: "All Magazine",
          Product: TariffName,
          SubID: subId,
          Amount: actualPriceINR,
          ActualPrice: OriginalPrice,
          OfferPrice: OfferPrice,
          Mobile: MobileNumber,
          Email: EmailId,
          OrderID: OrderId,
          Platform: "Website",
          domain: window.location.hostname,
          UtmSource: "Paywall_experiment",
          UtmMedium: "paywall",
          UtmCampaign: "Paywall_experiment_may22",
          location: document && document.location,
          referrer: document && document.referrer
        });
    }
  },
  WebengagepaidDatawall(data) {
    const TariffName = get(data, ["Product"]);
    const subId = get(data, ["SubID"]);
    const actualPriceINR = get(data, ["Amount"]);
    const OriginalPrice = get(data, ["Original_Price"]);
    const OfferPrice = get(data, ["OfferPrice"]);
    const MobileNumber = get(data, ["mobile_number"]);
    const EmailId = get(data, ["email_id"], "");
    const OrderId = get(data, ["order_id"]);

    if (data) {
      window.webengage &&
        window.webengage.track("PG PayNow", {
          ProductCategory: "All Magazine",
          Product: TariffName,
          SubID: subId,
          Amount: actualPriceINR,
          ActualPrice: OriginalPrice,
          OfferPrice: OfferPrice,
          Mobile: MobileNumber,
          Email: EmailId,
          OrderID: OrderId,
          Platform: "Website",
          domain: window.location.hostname,
          UtmSource: "Paywall_experiment",
          UtmMedium: "paywall",
          UtmCampaign: "Paywall_experiment_may22",
          location: document && document.location,
          referrer: document && document.referrer
        });
    }
  },
  Webplayer(story, eventName, collection) {
    const magazineName = get(collection, ["name"], null);
    const magazineId = get(collection, ["id"], null);
    const storyId = get(story, ["id"], "");
    const storyHeadline = get(story, ["headline"], () => null);
    let sections = get(story, ["sections", 0], null);
    // const section = get(global.qtConfig, ["sections"], []);
    // const parentSection = section && section.find(s => sections["parent-id"] === s.id);

    if (story) {
      window.webengage &&
        window.webengage.track(eventName, {
          AudioMagazineID: magazineId,
          AudioMagazineTitle: magazineName,
          AudioID: storyId,
          AudioTitle: storyHeadline,
          // Section: get(parentSection, ["name"], null),
          section: get(sections, ["name"], null),
          Platform: "Website",
          domain: window.location.hostname,
          location: document && document.location,
          referrer: document && document.referrer
        });
    }
  }
};

export const LoadVuukle = {
  scriptLoaded: false,
  userRegistered: false,
  registerUser() {
    if (this.userRegistered) {
      return;
    }
    global.userPromise
      .then(user => {
        const { username, userEmail } = user;
        this.userRegistered = true;
        userEmail &&
          isNaN(userEmail) &&
          wretch(`/v/api/vuukle/${userEmail}`)
            .get()
            .text(val => val)
            .then(function(response) {
              const profile = JSON.stringify({
                username: username,
                email: userEmail,
                public_key: get(global.qtConfig, ["vuukle", "public_key"]) || "",
                signature: response
              });
              const signature = window.btoa(profile);
              get(window, ["vuukleLogin"]) && window.vuukleLogin(signature);
            });
      })
      .catch(() => {
        console.warn("User attribution error occured");
      });
  },
  loadScript(onload, vikatanHosts) {
    let script1, script2;
    if (!this.scriptLoaded) {
      script1 = document.createElement("script");
      script1.type = "text/javascript";
      //  script1.defer = true;
      script1.src = "https://cdn.vuukle.com/platform.js";
      script2 = document.createElement("script");
      script2.innerHTML = `var VUUKLE_CONFIG={singlePage: true,host: "vikatan.com","emotes": {
        "firstName": "ஹாப்பி",
        "fourthName": "லவ்",
        "fifthName": "கோபம்",
        "sixthName": "வருத்தம்",
        disable: [2, 3],
        "fourthImg" : "https://${
          global.qtConfig["cdn-image"]
        }/vikatan/2019-11/6d234603-1a98-4ba1-9d75-0a890fd36e42/loveemoji.svg"
    },apiKey:"${get(global.qtConfig, ["vuukle", "public_key"]) || ""}",comments: { transliteration:{
          language: "ta",
          enabledByDefault: true,
          }, auth: { password: false,vuukle: true,sso: { onClick : function() { return window.location.replace("${
            vikatanHosts.login
          }?redirect_url=${global.location.href}") }   } } } ,language:"ta",endlessMode:!0};`;
      const node = document.getElementsByTagName("script")[0];
      node.parentNode.insertBefore(script2, node);
      node.parentNode.insertBefore(script1, node);
      this.scriptLoaded = true;
      onload();
    } else {
      onload();
    }
  },
  register(story, vikatanHosts) {
    const that = this;
    this.loadScript(() => {
      let script;
      script = document.createElement("script");
      let id = story.id;
      // var newStr = id.replace(/-/g, "");
      const vuukleInitializerListenerId = setInterval(function() {
        if (window.VUUKLE_PLATFORM === 1) {
          clearInterval(vuukleInitializerListenerId);
          window.removeVuukleWidgets(id);
          const ele = document.getElementById(`vuukle-widget-${id}`);
          script.innerHTML = `window.newVuukleWidgets({elementsIndex:"${id}",articleId:"${id}",img:"https://${
            global.qtConfig["cdn-image"]
          }/${story["hero-image-s3-key"]}",title:"${story.headline
            .replace(/"/g, '\\"')
            .replace(/(\r\n|\n|\r)/gm, "")}",tags:"",url:"${story.url}"});`;
          if (typeof ele !== "undefined" && ele != null) {
            ele.appendChild(script);
          }
          that.registerUser();
        }
      });
    }, vikatanHosts);
  }
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

const getOS = () => {
  var Name = "unknown";
  if (navigator.userAgent.indexOf("Win") !== -1) Name = "windows";
  if (navigator.userAgent.indexOf("Mac") !== -1) Name = "mac";
  if (navigator.userAgent.indexOf("Linux") !== -1) Name = "linux";
  if (navigator.userAgent.indexOf("Android") !== -1) Name = "android";
  if (navigator.userAgent.indexOf("like Mac") !== -1) Name = "ios";
  return Name;
};

export const EVOLOK = {
  inited: false,
  loadScript(pageType, { story = {}, collection = {} }, handleMeteringSuccess) {
    if (global.userPromise) {
      global.userPromise.catch(() => ({})).then(user => {
        const param = { site: "Vikatan" };
        const magazineName = get(collection, ["magazineEntity", "name"], null);
        const magazineEnName = get(collection, ["magazineEnName"], null);
        const issueName = get(collection, ["name"], null);
        const magazineDate = get(collection, ["collection-date"], null);
        const issueId = get(collection, ["id"], null);
        const magazineId = get(collection, ["magazineEntity", "id"], null);
        const magazineDateZone = magazineDate && new Date(magazineDate).toISOString().substring(0, 19);
        if (pageType === "story-page") {
          const sections = [get(story, ["sections", 0], {})];
          const parentSection = getParentSection(sections[0]);
          if (parentSection) {
            sections.unshift(parentSection);
          }
          const tags = get(story, ["tags"], null);
          const heroImage = get(story, ["hero-image-s3-key"], null);
          const tagcomma = tags && tags.map(x => x.name).join(",");
          const publishedAt = get(story, ["published-at"], null);
          const publishedAtZone = publishedAt && new Date(publishedAt).toISOString().substring(0, 19);
          const updatedAt = get(story, ["last-published-at"], null);
          const updatedAtZone = updatedAt && new Date(updatedAt).toISOString().substring(0, 19);
          const magazineCover = get(collection, ["metadata", "cover-image", "cover-image-url"], null);
          param.articleId = get(story, ["id"]);
          param.title = get(story, ["headline"]);
          param.authors = get(story, ["author-name"]);
          param.section = get(sections, [0, "name"]) || "";
          param.category = get(sections, [1, "name"]) || "";
          param.article_url = get(story, ["url"]);
          param.tags = tagcomma;
          param.thumbnail_url = `https://${global.qtConfig["cdn-image"]}/${heroImage}`;
          param.premium = isPremium(story);
          param.content_type = "article";
          param.channel = "WEB";
          param.publishDate = `${publishedAtZone}+05:30`;
          param.lastModifiedDate = `${updatedAtZone}+05:30`;
          param.storyTemplate = get(story, ["story-template"]);
          if (issueName) {
            param.issueName = issueName;
            param.magazineEnName = magazineEnName;
            param.issueDate = `${magazineDateZone}+05:30`;
            param.issueId = issueId;
            param.magazineName = magazineName;
            param.magazineId = magazineId;
            param.magazineCoverImage = magazineCover;
          }
        } else if (pageType === "magazine-page") {
          param.issueName = issueName;
          param.magazineEnName = magazineEnName;
          param.issueDate = `${magazineDateZone}+05:30`;
          param.issueId = issueId;
          param.premium = true;
        } else if (pageType === "home-page") {
          param.title = get(collection, ["data", "title"], null);
          param.article_url = global.qtConfig["sketches-host"];
          param.section = "Home";
        } else {
          param.title = get(collection, ["title"], null);
          param.article_url = get(collection, ["data", "breadcrumbs", "url"], null);
          param.collection_name = get(collection, ["data", "breadcrumbs", "name"], null);
          param.section = get(collection, ["data", "parentSection", "name"], null);
          param.sub_section = get(collection, ["data", "section", "name"], null);
        }
        param.pType = pageType;

        const getLocatoin = get(user, ["location", "ping", "data"], {});

        param.geocity = get(getLocatoin, ["city"], "");
        param.geo = get(getLocatoin, ["Country"], "");
        const params = JSON.stringify(param);

        // ENVIRONMENT VARIABLES
        const evoClient = document.location.hostname.endsWith("vikatan-beta-new.quintype.io") === true ? "vik" : "ev";
        const evoEnv = document.location.hostname.endsWith("vikatan-beta-new.quintype.io") === true ? ".uat" : "";
        const evHost =
          document.location.hostname.endsWith("vikatan-beta-new.quintype.io") === true ? ".evolok.net" : ".vikatan.com";

        // geo: "in"geocity: "jamshedpur"geostate: "jharkhand"
        // METERING INITIALISATION

        // eslint-disable-next-line no-undef
        EV.Em.init({
          url: "https://" + evoClient + evoEnv + evHost + "/acd/api/3.0",
          sidCookieDomain:
            document.location.hostname.endsWith("beta.vikatan.com") === true ? "beta.vikatan.com" : "vikatan.com",
          brand: "Vikatan"
        });

        // METERING CALL
        // eslint-disable-next-line no-undef
        if (handleMeteringSuccess) {
          // eslint-disable-next-line no-undef
          EV.Em.authorize(params, handleMeteringSuccess, this.handleMeteringError);
        } else {
          // eslint-disable-next-line no-undef
          EV.Em.authorize(params, this.handleMeteringSuccess, this.handleMeteringError);
        }
      });
    }
  },
  handleMeteringSuccess(response) {
    console.log("response---->", response);
    if (response.result === "ALLOW_ACCESS") {
      console.log("ALLOW_ACCESS");
    } else if (response.requireEntitlement) {
      console.log("REQUIRE_LOGIN_WITH_ENTITLEMENT");
    } else {
      if (response.result === "REQUIRE_LOGIN") {
        console.log("REQUIRE_LOGIN");
      } else {
        console.log("REQUIRE_ENTITLEMENT");
      }
    }
  },
  handleMeteringError(error) {
    console.log("error", error);
  }
};

export const GOOGLE_INTERSTITAL = {
  scriptLoaded: false,
  loadScript() {
    if (!this.scriptLoaded) {
      const scriptContainer = document.getElementById("affIntlMain_a");
      if (scriptContainer) {
        const script1 = document.createElement("script");
        script1.type = "text/javascript";
        script1.innerHTML = `
          function __affIntlMain(e,t,a){e.googletag=e.top.googletag||{cmd:[]},e.__affAu=t;var n=e.innerWidth>0?e.innerWidth:screen.width;if(e.__affIntlReq=!1,e.__affAuSl,2===a||a===n<760){googletag.cmd.push(function(){e.__affAuSl=googletag.defineOutOfPageSlot(e.__affAu,googletag.enums.OutOfPageFormat.INTERSTITIAL),e.__affAuSl&&(e.__affAuSl.addService(googletag.pubads()),googletag.enableServices());var t=function(e){googletag.pubads().removeEventListener("slotRenderEnded",t),f(!0,!1)};googletag.pubads().addEventListener("slotRenderEnded",t),setTimeout(f,2e3,!1,!0)});function f(t,a){if(!e.__affIntlReq&&(e.__affIntlReq=!0,t||a)){for(var n=googletag.pubads(),f=n.getSlots(),d=0;d<f.length;d++)if(!0===f[d].getOutOfPage()&&""===f[d].getEscapedQemQueryId()){var i=f[d].getAdUnitPath();googletag.destroySlots([f[d]]);var l=googletag.defineOutOfPageSlot(i,googletag.enums.OutOfPageFormat.INTERSTITIAL);l&&(l.addService(googletag.pubads()),1===f.length&&googletag.enableServices(),googletag.display(l),!0===n.isInitialLoadDisabled()&&n.refresh([l]),t?console.log("tv:re"):a&&console.log("tv:ct"));break}}}}}
          __affIntlMain(window, "/3849069/Vikatan_Google_Interstitial_New", 2);
        `;
        scriptContainer.appendChild(script1);
        this.scriptLoaded = true;
      } else {
        console.error("Script container not found.");
      }
    }
  }
};

export const GTM_RETARGETTING = {
  reTargettingUser() {
    let script1, script2;
    if (!this.scriptLoaded) {
      script1 = document.createElement("script");
      script1.type = "text/javascript";
      //  script1.defer = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-1045017461";
      script2 = document.createElement("script");
      script2.innerHTML = ` window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-1045017461');`;
      const node = document.getElementsByTagName("script")[0];
      node.parentNode.insertBefore(script2, node);
      node.parentNode.insertBefore(script1, node);
      this.scriptLoaded = true;
    }
  }
};

export const PGAD = {
  inited: false,
  storyFeedInited: false,
  loadScript() {
    if (this.inited) {
    }
  }
};

export const STORY_TRACKING = {
  ping(args, story) {
    global.userPromise.catch(() => null).then(user => {
      STORY_TRACKING.pingStoryApi(user, args, story);
    });
  },

  pingStoryApi(user, args, story) {
    let customer = {
      userId: get(user, ["userId"], ""),
      username: get(user, ["username"], ""),
      userType: get(user, ["userType"], ""),
      userEmail: get(user, ["userEmail"], ""),
      userJoinDate: get(user, ["userJoinDate"], "")
    };
    let userSess;

    const now = new Date();
    const minutes = 30;
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const uniq = Math.random()
      .toString(16)
      .slice(2);
    const uniqDate = new Date().getTime();
    const uniqId = `${uniqDate}i${uniq}`;
    if (!universalCookie.get("vikatanSessionID")) {
      universalCookie.set("vikatanSessionID", `${uniqId}`, {
        path: "/",
        domain: ".vikatan.com",
        expires: now
      });
    }

    if (get(global, ["webengage", "state", "getSession"])) {
      userSess = global.webengage.state.getSession();
      Object.assign(customer, {
        ip: get(userSess, ["ip"]),
        city: get(userSess, ["we_city"]),
        country: get(userSess, ["we_country"])
      });
    }

    let sections = get(story, ["sections", 0], null);

    global.fetch(`https://ping.vikatan.com/events`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          user: {
            cookie: getDeviceTrackerId(),
            customer: customer
          },
          event: {
            type: "ScrollDepth",
            timestamp: Date.now(),
            eventattribute: {
              AID: story.id,
              Atitle: story.headline,
              Atype: isPremium(story) ? "P" : "F",
              author: get(story, ["author-name"]),
              url: global.location.href,
              path: story.slug,
              pageType: "story-page",
              section: get(sections, ["name"], null),
              date: get(story, ["published-at"]),
              subtitle: get(story, ["sub-headline"]),
              domain: window.location.hostname,
              source: "web",
              location: document && document.location,
              referrer: document && document.referrer,
              percentage: args
            }
          },
          device: {
            devicetype: getDeviceType(),
            CookieID: getDeviceTrackerId(),
            Platform: getOS(),
            userAgent: navigator.userAgent,
            SessionID: universalCookie.get("vikatanSessionID") || uniqId,
            GACuid: "",
            DeepCookie: "",
            ampthinmint: universalCookie.get("ampthinmint") || ""
          }
        }
      })
    });
  }
};
